<template>
  <li class='order-archive-single'>
    <router-link :to='{ name: `order-details`, params: { id: order.id } }' class='order-link' :aria-current='aria_current'>
      <b-row class='order-title-row mb-1'>
        <b-col>
          <h2>Order #{{ order.id }}</h2>
        </b-col>
        <b-col cols=12 xl=auto>{{ order.date }}</b-col>
      </b-row>
      <div class='company-name'>
        <span v-if="order.billing_company">
          {{ order.billing_company }}
        </span>
        <span v-if="order.billing_name && order.billing_company">
          &ndash;
        </span>
        <span v-if="order.billing_name">
          {{ order.billing_name }}
        </span>
      </div>
      <div class='status-label' :class='order_status.key'>{{ order_status.label }}</div>
    </router-link>
  </li>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers( 'orders' );

export default {
  name: 'OrdersSidebarSingle',
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  computed: {

    aria_current () {
      const active_id = this.$route.params.id ?? null;
      return ( active_id && ( active_id == this.order.id ) ) ? 'page' : null;
    },

    order_status() {
      return this.statuses.find( x => this.order.status == x.key );
    },

    ...mapState([
      'statuses'
    ]),

  },
}
</script>


<style lang="scss">
.order-archive-single {
  .order-link {
    border-radius: 0.5rem;
    background-color: var( --white );
    border: 1px solid var( --bs-border-color );
    color: var( --dark );
    text-align: left;
    display: block;
    padding: 1rem;
    transition-duration: var( --transition-duration );
    transition-timing-function: var( --transition-timing-function );
    transition-property: color, background-color, border-color;
    &:hover,
    &:focus,
    &[aria-current=page] {
      text-decoration: none;
      background-color: var( --blue );
      border-color: var( --blue );
      &,
      .status-label {
        color: white;
      }
    }
  }
  .order-title-row {
    align-items: baseline;
  }
  h2 {
    font-size: 1em;
  }
  .date {
    font-size: 12px;
  }
  .status-label {
    // color vars added in main.scss for simplicity of scss loop
    color: var( --status-color, var( --body-color, black ) );
    transition: var( --transition-timing );
  }
}
</style>