<template>
  <div id="details-grid">
    <OrdersSidebar/>
    <div id='main-panel'>
      <router-view/>
    </div>
  </div>
</template>


<script>
import OrdersSidebar from './sidebar';

export default {
  name: 'OrdersArchive',
  components: {
    OrdersSidebar,
  },
  created () {
    this.$store.dispatch( 'orders/load_statuses' );
    this.$store.dispatch( 'orders/load_shipping_methods' );
    this.$store.dispatch( 'orders/load_guest_customer' );
    this.$store.dispatch( 'orders/load_countries' );
  },
}
</script>


<style scoped lang='scss'>
#details-grid {
  display: grid;
  width: 100%;
  grid-template-areas: 'sidebar main';
  grid-template-columns: minmax( 0, 3fr ) minmax( 0, 7fr );
}
@media ( min-width: 992px ) {
  #details-grid {
    grid-template-columns: minmax( 0, 1fr ) minmax( 0, 3fr );
  }
}
.orders-sidebar {
  grid-area: sidebar;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  & /deep/ .results {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    #orders-list {
      flex: 1 1 auto;
      overflow: auto;
      height: 0;
    }
  }
}
#main-panel {
  grid-area: main;
  display: flex;
  flex-flow: column nowrap;
}
</style>