<template>
  <div v-if='statuses' class='orders-sidebar'>
    
    <div class='filter-controls'>
      <b-input type='search' placeholder='Search customer or order #' v-model='search_term' @input='load_page(1)' />
      <vue-multiselect
        placeholder='Filter by status'
        label='label'
        track-by='key'
        :options='statuses'
        :multiple='true'
        :close-on-select='false'
        :searchable='false'
        :allowEmpty='true'
        :value='status_filter'
        @input='update_status_filter'
        :showLabels='false'
        >
        <template slot='selection' slot-scope='{ values, isOpen }'>
          <span v-if='values.length' :style='{ display: isOpen ? `none` : `block` }' class='multiselect__single'>{{ values.length }} statuses selected</span>
        </template>
      </vue-multiselect>
      <b-button class='refresh' @click='load_page( current_page )' variant='link'>
        <font-awesome-icon icon="fa-regular fa-arrows-rotate" />
        <span>Refresh</span>
      </b-button>
    </div>

    <div class='results'>
      <jj-loading v-if='loading_orders' centered />
      <template v-else>
        <ul id='orders-list'>
          <li v-for='order in orders' :key='order.id' is='OrdersSidebarSingle' :order='order'/>
        </ul>
        <div v-if='total_rows' class='pagination-wrap'>
          <div>Showing {{ current_page_min }}-{{ current_page_max }} of {{ total_rows }}</div>
          <b-pagination
            :value="current_page"
            @input='load_page'
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="orders-list"
            hide-goto-end-buttons
            limit=5
            hide-ellipsis
            />
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import axios from '@/axios.js';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapMutations, mapActions } = createNamespacedHelpers( 'orders' );
import OrdersSidebarSingle from './single';

import EventBus from '@/event-bus';

export default {
  name: 'OrdersSidebar',
  components: {
    OrdersSidebarSingle,
  },
  data () {
    return {
      abort_controller: null,
      orders: [],
      search_term: '',
      per_page: 10,
      current_page: 1,
      total_rows: null,
      max_num_pages: null,
      loading_orders: false,
      creating_new_order: false,
    }
  },
  computed: {
    ...mapState({
      statuses: 'statuses',
      store_status_filter: 'status_filter',
    }),
    status_filter: {
      get () {
        return this.store_status_filter;
      },
      set ( value ) {
        this.set_status_filter( value );
      }
    },
    current_page_min () {
      return ( this.current_page - 1 ) * this.per_page + 1;
    },
    current_page_max () {
      return ( this.current_page - 1 ) * this.per_page + this.per_page > this.total_rows ? this.total_rows : ( this.current_page - 1 ) * this.per_page + this.per_page;
    },
  },
  methods: {

    ...mapMutations({
      set_status_filter: 'status_filter',
    }),

    update_status_filter ( value ) {
      this.set_status_filter( value );
      this.load_page(1);
    },

    load_page: _.debounce( function ( page ) {
      this.load_orders ({ page });
    }, 250 ),

    load_orders ( args = {} ) {
      if ( this.abort_controller ) {
        this.abort_controller.abort();
      }
      this.abort_controller = new AbortController();
      this.loading_orders = true;
      axios
        .get( '/orders', {
          signal: this.abort_controller.signal,
          params: Object.assign( args, {
            per_page: this.per_page,
            status: this.status_filter.map( status => status.key ),
            search_term: this.search_term,
          }),
        })
        .then( response => {
          this.orders = response.data.orders;
          this.total_rows = response.data.total_rows;
          this.current_page = response.data.page;
          this.max_num_pages = response.data.max_num_pages;
          this.loading_orders = false;
        })
        .catch( e => {
          this.orders = [];
          this.total_rows = 0;
          if ( _.get( e, 'constructor.name' ) != 'Cancel' ) {
            this.loading_orders = false;
          }
        })
    },

    receive_order_status_update ( data ) {
      this.orders = this.orders.map( order => {
        if ( order.id == data.id ) {
          order.status = data.status;
        }
        return order;
      });
    },

    receive_order_deleted ( id ) {
      this.$jjToast({
        type: 'success',
        title: 'Deleted',
        content: 'Deleted order',
      });
      if ( this.orders.some( x => x.id == id ) ) {
        this.load_orders();
      }
    },

  },
  created () {
    this.load_orders();
    EventBus.$on( 'order-status-updated', this.receive_order_status_update );
    EventBus.$on( 'order-deleted', this.receive_order_deleted );
    if ( this.statuses.length ) {
      // not sure what this was for but something has obviously changed
      // this function doesn't exist and threw and error (console only, didn't affect functionality)
      // this.loading_orders();
    } else {
      let unwatch = this.$watch( 'statuses', () => {
        this.load_orders();
        unwatch();
      });
    }
  },
  beforeDestroy () {
    EventBus.$off( 'order-status-updated', this.receive_order_status_update );
    EventBus.$off( 'order-deleted', this.receive_order_deleted );
  },
}
</script>


<style lang="scss">
.orders-sidebar {
  border-right: 1px solid var( --bs-border-color );
  .filter-controls {
    padding: 1.25rem;
    > .row {
      --gutter-y: 2rem;
    }
    input,
    select,
    button {
      display: block;
      width: 100%;
    }
  }
  .results {
    background-color: var( --medium-light );
    border-top: 1px solid var( --bs-border-color );
    position: relative;
  }
  #orders-list {
    list-style: none;
    margin: 0;
    padding: 0.25rem;
    li:not(:first-child) {
      margin-top: 0.25rem;
    }
  }
  .pagination-wrap {
    padding: 0.75rem;
    text-align: center;
    border-top: 1px solid var( --bs-border-color );
    background-color: white;
    font-size: 0.9em;
  }
  .pagination {
    justify-content: center;
    margin: 0.5rem 0 0;
  }
  .multiselect {
    margin-top: 0.75rem;
  }
  .refresh {
    padding: 0;
    margin: 0.75em auto 0;
    width: auto !important;
  }
}
</style>